import clsx from "clsx";
import React from "react";

import styles from "styles/misc.module.css";

export default function LabelInput({
    label = "",
    placeholder = "",
    type = "text",
    inputOptions = {},
    value,
    disabled = false,
    onChange = (val, e?: any) => {},
    onBlur = (e) => {},
    onFocus = (e) => {},
}) {
    return (
        <div className={styles.inputGroup}>
            <label className={styles.inputLabel}>{label}</label>
            <input
                {...inputOptions}
                type={type}
                placeholder={placeholder}
                className={styles.input}
                onChange={(e) => {
                    onChange(e.target.value, e)
                }}
                onBlur={onBlur}
                onFocus={onFocus}
                value={value}
                disabled={disabled}
            />
        </div>
    );
}

export function Input({
    placeholder = "",
    type = "text",
    inputOptions = {},
    value,
    onChange = (e) => {},
}) {
    return (
        <div className={styles.inputGroup}>
            <input
                {...inputOptions}
                type={type}
                placeholder={placeholder}
                /** @ts-ignore */
                className={clsx(styles.input, inputOptions?.className)}
                onChange={(e) => onChange(e.target.value)}
                value={value}
            />
        </div>
    );
}
