import Axios from "axios";

const axios = Axios.create({
  baseURL: "/",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Credentials": "true",
  },
});

axios.interceptors.request.use(
  (config: any) => {
    const crypto_league = localStorage.getItem("crypto_league");
    if (crypto_league) {
      // config.baseURL = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/${crypto_league}`
      config.baseURL = `/api/v2/${crypto_league}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const axiosAuth = Axios.create({
  baseURL: "/",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Credentials": "true",
  },
});

export default axios;
