import clsx from "clsx";
import React from "react";
import { Type } from "react-tooltip";

import styles from "styles/misc.module.css";

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: "fit" | "full";
  text?: string | JSX.Element;
  iconSize?: number;
  iconSrc?: string;
  outline?: boolean;
}
/**
 *
 * @param {object} props
 * @returns
 */
export default function Button({
  onClick,
  text,
  disabled = undefined,
  className = "",
  variant = "full",
  type = "button",
  iconSize = 16,
  iconSrc,
  outline
}: Props) {
  return (
    <button
      type={type}
      onClick={onClick}
      className={clsx(
        styles.button,
        className,
        variant === "fit" ? styles.button_fit : "",
        {
          [styles.hasIcon]: Boolean(iconSrc),
          [styles.isOutline]: Boolean(outline)
        }
      )}
      disabled={disabled}
    >
      {iconSrc && (<img src={iconSrc} height={iconSize} className={styles.buttonIcon} alt="logo" />)}
      {text}
    </button>
  );
}

/**
 *
 * @param {*} props
 * @returns
 */
export function SlimButton({ className, ...rest }: Props) {
  /** @ts-ignore */
  return <Button className={clsx(styles.slimButton, className)} {...rest} />;
}

/**
 * left arrow button
 */
export function BackButton({ className = "", text = "", ...rest }: Props) {
  return (
    /** @ts-ignore */
    <Button
      {...rest}
      className={clsx(styles.backButton, className)}
      text={
        <div>
          <img src="/images/icons/left-arrow.png" className={styles.backIcon} />
          {text && <span>{text}</span>}
        </div>
      }
    />
  );
}

/**
 * bleu button
 */
export function BlueButton({ className = "", ...rest }: Props) {
  /** @ts-ignore */
  return <Button {...rest} className={clsx(styles.blueButton, className)} />;
}
