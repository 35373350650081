import React from "react";
import toast from "react-hot-toast";
import styles from "@/styles/toast.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const Toast = ({ message = "", children = null }) => {
  return (
    <div className={styles.toastContainer}>
      {children ? children : <p>{message}</p>}
      <FontAwesomeIcon
        icon={faClose}
        className={styles.closeIcon}
        onClick={() => toast.dismiss()}
      />
    </div>
  )
}

export default Toast;