import axios, { AxiosResponse } from "axios";
import { axiosAuth } from "../utils/newAxios";

export async function requestCode(email: string): Promise<{ error?: string }> {
  try {
    let request = await axiosAuth.post("/api/auth/forgot-password/request", {
      email: email,
    });
    return request.data;
  } catch (err: unknown) {
    throw err;
  }
}

export async function changePassword(
  code: string,
  email: string,
  password: string
) {
  try {
    let pwd = await axiosAuth.post("/api/auth/forgot-password/change", {
      code,
      email,
      password,
    });
    return pwd.data;
  } catch (err: unknown) {
    throw err;
  }
}

export async function updateProfile({
  profile_name,
  team_name,
  birthdate,
  email,
}) {
  try {
    let res = await axiosAuth.post("/api/auth/profile", {
      profile_name,
      team_name,
      dob: birthdate,
      email,
    });
    return { success: res.data.success };
  } catch (err: unknown) {
    throw err;
  }
}

export async function signUpAccount(fields): Promise<AxiosResponse<any, any>> {
  try {
    let res = await axiosAuth.post(`/api/auth/signup`, fields);
    return res;
  } catch (err: unknown) {
    throw err;
  }
}

export async function getAuthCode(email: string) {
  try {
    let res = await axiosAuth.post(`/api/auth/code`, { email });
    return res;
  } catch (err: unknown) {
    throw err;
  }
}

export async function confirmAuthCode(email, code) {
  try {
    let res = await axiosAuth.post("/api/auth/confirm", {
      code,
      email,
    });
    return { success: res.data.success };
  } catch (err: unknown) {
    throw err;
  }
}
